export default {
  data() {
    return {
      texts: "",
    };
  },
  beforeMount() {
    this.texts = FILE.killSessionTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
    close() {
      this.$emit('emitClose')
      this.$store.commit('setDialogKillSession',false)

    },
    killSession() {
      if (this.$store.state.sToken !== "") {
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.delete(`${URI}/api/v1/${this.selectLanguage}/session`, config)
          .then((response) => {
            this.$store.commit('setDialogKillSession',false)
            localStorage.clear();
            this.$store.commit("setToken", "");
          })
          .catch((error) => {
          this.mixError(error.response.data.message,error.response.status);
          });
      }
    },
    exit() {
      this.$emit('emitClose')
      this.$store.commit('setDialogKillSession',false)
      // this.$router.push("/");
      this.$store.commit("setToken", "");
      this.$router.go();

    },
  },
  computed: {
    dialogKillSession() {
      return this.$store.state.dialogKillSession;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    dialogKillSession() {
      if (this.dialogKillSession) {
        this.texts = FILE.killSessionTexts[this.selectLanguage];
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.killSessionTexts[this.selectLanguage];
      }
    },
  },

};